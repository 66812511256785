import { Group, MotivUserData } from './types';

const generateHTMLFromJSON = (data: MotivUserData): string => {
  const generateGroupHTML = (group: Group): string => {
    let html = `<div style='margin-left: 20px;'>`;
    html += `<h4>${group.name}</h4>`;
    html += `<div style='margin-left: 20px;'>`;
    html += `<div>&nbsp;&nbsp;&nbsp;&nbsp;Created At: ${group.created_at}</div>`;

    if (group.notifications && group.notifications.length > 0) {
      html += '<h5>&nbsp;&nbsp;&nbsp;&nbsp;Notifications:</h5><ul>';
      group.notifications.forEach((notification) => {
        html += `<li>&nbsp;&nbsp;&nbsp;&nbsp;Notified At: ${notification.notified_at}</li>`;
      });
      html += '</ul>';
    }

    html += '</div></div>';
    return html;
  };

  let html =
    "<!DOCTYPE html><html lang='en'><head><meta charset='UTF-8'><meta name='viewport' content='width=device-width, initial-scale=1.0'><title>Motiv User Data</title></head><body><div>";

  data.outreach?.forEach((group) => {
    html += generateGroupHTML(group);
  });

  let emailDayOfReminder;
  let emailThreeDayReminder;
  if (data.emailReminderPreference) {
    emailDayOfReminder = data.emailReminderPreference.dayOf ? 'Yes' : 'No';
    emailThreeDayReminder = data.emailReminderPreference.threeDaysBefore ? 'Yes' : 'No';
  } else {
    emailDayOfReminder = emailThreeDayReminder =
      data.emailReminders === undefined || data.emailReminders === null ? 'Yes' : data.emailReminders ? 'Yes' : 'No';
  }

  let textDayOfReminder;
  let textThreeDayReminder;
  if (data.textReminderPreference) {
    textDayOfReminder = data.textReminderPreference.dayOf ? 'Yes' : 'No';
    textThreeDayReminder = data.textReminderPreference.threeDaysBefore ? 'Yes' : 'No';
  } else {
    textDayOfReminder = textThreeDayReminder =
      data.textReminders === undefined || data.textReminders === null ? 'Yes' : data.textReminders ? 'Yes' : 'No';
  }

  html += '<h5>Reminder Notification Preferences:</h5><ul>';
  html += `<li>Email:<ul>`;
  html += `<li>3 days before: ${emailThreeDayReminder}</li>`;
  html += `<li>Same day of: ${emailDayOfReminder}</li>`;
  html += `</ul></li>`;

  html += `<li>Text:<ul>`;
  html += `<li>3 days before: ${textThreeDayReminder}</li>`;
  html += `<li>Same day of: ${textDayOfReminder}</li>`;
  html += `</ul></li>`;
  html += '</ul>';

  html += '<h5>Reminder Days:</h5><ul>';
  data.reminderDays?.forEach((day) => {
    html += `<li>&nbsp;&nbsp;&nbsp;&nbsp;${day}</li>`;
  });
  html += '</ul>';

  if (data.stopMessageReceivedAt) {
    html += `<h5>Stop Message Received: ${data.stopMessageReceivedAt}</h5>`;
  }

  if (data.onHoldActivePatientsTimestamp && data.onHoldActivePatientsTimestamp.length > 0) {
    html += `<h4>On Hold Active Patients Timestamps</h4>`;
    html += `<ul>`;
    data.onHoldActivePatientsTimestamp.forEach((timestamp) => {
      html += `<li>&nbsp;&nbsp;&nbsp;&nbsp;${timestamp}</li>`;
    });
    html += `</ul>`;
  }

  if (data.removedFromOnHoldTimestamp && data.removedFromOnHoldTimestamp.length > 0) {
    html += `<h4>Removed From On Hold Timestamps</h4>`;
    html += `<ul>`;
    data.removedFromOnHoldTimestamp.forEach((timestamp) => {
      html += `<li>&nbsp;&nbsp;&nbsp;&nbsp;${timestamp}</li>`;
    });
    html += `</ul>`;
  }

  html += '</div></body></html>';
  return html;
};

export default generateHTMLFromJSON;
