import { useQuery } from '@apollo/client';
import { GET_SINGLE_APPOINTMENT } from 'api/appointment/appointment.query';
import { useOrganization } from 'hooks/useOrganization';
import React, { useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import { useParams } from 'react-router-dom';
import { paths } from 'router/paths';
import { capitalizeWords } from 'utils/helpers';

import { BaseLayout } from 'components/layout/BaseLayout/BaseLayout';
import { Header } from 'components/layout/BaseLayout/Header';
import LoadingPage from 'components/ui/LoadingPage/LoadingPage';
import { Typography } from 'components/ui/Typography/Typography';

import * as Styled from './MeetYourCoach.styles';

export const MeetYourCoach: React.FC = () => {
  const { t } = useTranslation('translation', { keyPrefix: 'pages.meetYourCoach' });
  const navigate = useNavigate();
  const { appointmentId, actionType } = useParams();
  const { city } = useOrganization();

  if (!appointmentId || !actionType) {
    navigate(paths.homePage);
  }

  const { data: appointmentData, loading: isAppointmentLoading } = useQuery(GET_SINGLE_APPOINTMENT, {
    variables: {
      id: appointmentId,
    },
    fetchPolicy: 'no-cache',
  });

  const provider = useMemo(() => appointmentData?.appointment?.provider, [appointmentData?.appointment?.provider]);

  const isTherapist = useMemo(
    () => provider?.qualifications?.toLocaleLowerCase().includes('therapist'),
    [provider?.qualifications],
  );

  const isOrientation = useMemo(
    () => appointmentData?.appointment?.appointment_type?.name?.toLocaleLowerCase().includes('orientation'),
    [appointmentData?.appointment?.appointment_type?.name],
  );

  if (isAppointmentLoading) {
    return <LoadingPage />;
  }

  const basicPath = paths.appointmentActionConfirmation
    .replace(':appointmentId', appointmentId || '')
    .replace(':actionType', actionType || '');

  const description = isOrientation
    ? t('coachDescriptionMotivTeam')
    : isTherapist
    ? t('therapistDescription', { city })
    : t('coachDescription1', { city });

  return (
    <BaseLayout
      header={
        <Header
          showBack={false}
          variant="back"
          title={isOrientation ? 'Appointment Details' : isTherapist ? t('yourTherapist') : t('yourCoach')}
        />
      }
      footer={<div></div>}
    >
      <Styled.MeetYourCoachContainer>
        <Styled.Header>
          <Typography variant="Headline2">{t('meetYourCoach')}</Typography>
        </Styled.Header>
        <Styled.PageContent>
          {provider?.avatar_url && <Styled.Avatar src={provider?.avatar_url} />}
          <>
            <Styled.CoachName variant="Headline2" color="black.900">
              {provider?.name}
            </Styled.CoachName>
            <Styled.CoachTitle variant="Helper1" color="black.500">
              <p>{isOrientation ? 'Motiv Team' : isTherapist ? 'Therapist' : t('healthAndWellness')}</p>
            </Styled.CoachTitle>
            <Styled.CoachDescription>
              {capitalizeWords(provider?.first_name)}
              {description}
            </Styled.CoachDescription>
          </>
        </Styled.PageContent>
        <Styled.ButtonsContainer>
          {/* <Styled.SButton variant="link" size="md" onClick={onDoItLaterClick}>
          {t('doItLater')}
        </Styled.SButton> */}
          <Styled.SButton variant="primary" size="md" onClick={() => navigate(basicPath)}>
            {t('Next')}
          </Styled.SButton>
        </Styled.ButtonsContainer>
      </Styled.MeetYourCoachContainer>
    </BaseLayout>
  );
};
