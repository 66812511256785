import { ApolloError, useQuery } from '@apollo/client';
import { GET_USER_ORGANIZATION } from 'api/user/user.query';

import { Organization } from '../__generated__/graphql';

interface UseOrganizationReturn {
  organization: Organization | undefined | null;
  isLoading: boolean;
  error: ApolloError | undefined;
  city: string;
}

export const useOrganization = (): UseOrganizationReturn => {
  const { data, loading, error } = useQuery(GET_USER_ORGANIZATION, {
    variables: { for_client: true },
  });

  const city = data?.organization?.name?.toLowerCase().trim() === 'lee health' ? 'Florida' : 'Illinois';

  return {
    organization: data?.organization,
    isLoading: loading,
    error,
    city,
  };
};
