import { gql } from '__generated__/gql';

export const GET_CURRENT_USER_DATA = gql(`
  query currentUserId {
    currentUser {
      id
      first_name
      last_name
      dob
      email
      phone_number
    }
  }
`);

export const GET_USER_ORGANIZATION = gql(`
  query getOrganization($for_client: Boolean = true) {
    organization(for_client: $for_client) {
      id
      name
      created_at,
      organization_email
      location {
        id
        cursor
        city
        country
      }
    }
  }
`);

export const GET_CURRENT_USER_PROVIDER = gql(`
query currentUserProvider {
  provider {
    first_name
    name
    id
    avatar_url
    phone_number
  }
}
`);

export const GET_CURRENT_USER_PROVIDERS = gql(`
  query currentUserProviders {
    currentUser {
      id
      providers {
        id
        name
        phone_number
        qualifications
      }
    }
  }
`);

export const GET_TOKEN_VALIDITY = gql(`
  query getValidateToken($signup_token: String, $reset_token: String) {
    validateSignupToken(token: $signup_token)
    validateResetPasswordToken(token: $reset_token)
    userFromToken: basicUserInfoFromToken(token: $signup_token) {
      id
      first_name
      has_created_password
      dietitian {
        id
        full_name
      }
      user_who_invited_member {
        id
        full_name 
      }
    }
  }
`);

export const GET_CURRENT_USER_FITBIT = gql(`
  query currentUserFitbit {
    currentUser {
      id
      fitbit {
        is_enabled
        id
      }
    }
  }
`);

export const GET_USER_LOCATION = gql(`
  query currentUser {
    currentUser{
      id
      location {
        id
        city
        line1
        line2
        zip
        state
      }
    }
  }
`);
