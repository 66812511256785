import { useMutation, useQuery } from '@apollo/client';
import { GET_FORMANSWERGROUP_BY_NAME, UPDATE_FORMANSWER_GROUP } from 'api/notifications/notifications.query';
import { GET_CURRENT_USER_DATA } from 'api/user/user.query';
import { isEmpty } from 'lodash';
import React, { useEffect, useMemo, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { paths } from 'router/paths';
import generateHTMLFromJSON from 'utils/generateHTMLFromJson';
import { Mixpanel } from 'utils/mixpanel';
import { ApptReminderDay, DaysOfWeek, MotivUserData } from 'utils/types';

import LeftLabelCheckbox from 'components/form/Checkbox/LeftLabelCheckbox/LeftLabelCheckbox';
import { BaseLayout } from 'components/layout/BaseLayout/BaseLayout';

import { Container, NotificationContainer } from './Notifications.styles';

interface ReminderDays {
  name: DaysOfWeek;
}

const ApptReminderPreferences: {
  type: 'email' | 'text';
  heading: string;
  options: { type: ApptReminderDay; display: string }[];
}[] = [
  {
    type: 'email',
    heading: 'Appointment EMAIL Reminders sent:',
    options: [
      {
        type: ApptReminderDay.ThreeDaysBefore,
        display: '3 days before appt',
      },
      {
        type: ApptReminderDay.DayOf,
        display: 'Same day of appt',
      },
    ],
  },
  {
    type: 'text',
    heading: 'Appointment TEXT Reminders sent:',
    options: [
      {
        type: ApptReminderDay.ThreeDaysBefore,
        display: '3 days before appt',
      },
      {
        type: ApptReminderDay.DayOf,
        display: 'Same day of appt',
      },
    ],
  },
];

export const Notification: React.FC = () => {
  const navigate = useNavigate();
  const { data } = useQuery(GET_CURRENT_USER_DATA, { fetchPolicy: 'network-only' });
  const { data: motiv_user_form, loading: userDataLoading } = useQuery(GET_FORMANSWERGROUP_BY_NAME, {
    variables: {
      userId: data?.currentUser?.id,
      name: 'Motiv user data',
    },
    skip: isEmpty(data?.currentUser?.id),
    fetchPolicy: 'network-only',
  });
  const [updateFormAnswer, { loading: updateLoading }] = useMutation(UPDATE_FORMANSWER_GROUP);

  const motivUserDataForm = motiv_user_form?.formAnswerGroups?.at(0);

  const jsonFormAnswer = motivUserDataForm?.form_answers.find((form) => form.label === 'json_data') || null;

  const prettyJsonFormAnswer =
    motivUserDataForm?.form_answers.find((form) => form.label === 'pretty_json_data') || null;

  const parsedJSON: MotivUserData =
    jsonFormAnswer && JSON.parse(jsonFormAnswer?.answer?.match(/<p[^>]*>(.*?)<\/p>/)?.[1] ?? '{}');

  const [currentUpdating, setCurrentUpdating] = useState('');

  const reminderDays: ReminderDays[] = [
    {
      name: DaysOfWeek.Monday,
    },
    {
      name: DaysOfWeek.Wednesday,
    },
    {
      name: DaysOfWeek.Friday,
    },
  ];

  useEffect(() => {
    Mixpanel.track_pageview({ page: 'Settings - Notifications' });
  }, []);

  const updateFormAnswerGroup = async () => {
    try {
      const prettyJson = generateHTMLFromJSON(parsedJSON);
      await updateFormAnswer({
        variables: {
          input: {
            id: motivUserDataForm?.id,
            form_answers: [
              {
                answer: `<p style="display:none;">${JSON.stringify(parsedJSON)}</p>`,
                custom_module_id: jsonFormAnswer?.custom_module?.id,
                user_id: data?.currentUser?.id,
              },
              {
                answer: prettyJson,
                custom_module_id: prettyJsonFormAnswer?.custom_module?.id,
                user_id: data?.currentUser?.id,
              },
            ],
          },
        },
        refetchQueries: [GET_FORMANSWERGROUP_BY_NAME],
        fetchPolicy: 'network-only',
      });
    } catch (error) {
      console.error('Failed update user form..', (error as Error).stack);
    }
  };

  const onChange = async (day: DaysOfWeek) => {
    setCurrentUpdating(day);
    if (parsedJSON) {
      if (parsedJSON.reminderDays.includes(day)) {
        // Day exists, remove it
        parsedJSON.reminderDays = parsedJSON.reminderDays.filter((d) => d !== day);
      } else {
        // Day doesn't exist, add it
        parsedJSON.reminderDays.push(day);
      }
      // Return the updated parsedJSON
      await updateFormAnswerGroup();
    }
  };

  const { emailReminderPreference, textReminderPreference } = useMemo(() => {
    let emailReminderPreference = { dayOf: true, threeDaysBefore: true };
    let textReminderPreference = { dayOf: true, threeDaysBefore: true };
    if (parsedJSON) {
      if (parsedJSON.emailReminderPreference) {
        emailReminderPreference = parsedJSON.emailReminderPreference;
      } else if ('emailReminders' in parsedJSON) {
        emailReminderPreference = {
          dayOf: parsedJSON.emailReminders ?? true,
          threeDaysBefore: parsedJSON.emailReminders ?? true,
        };
      }

      if (parsedJSON.textReminderPreference) {
        textReminderPreference = parsedJSON.textReminderPreference;
      } else if ('textReminders' in parsedJSON) {
        textReminderPreference = {
          dayOf: parsedJSON.textReminders ?? true,
          threeDaysBefore: parsedJSON.textReminders ?? true,
        };
      }
    }
    return { emailReminderPreference, textReminderPreference };
  }, [parsedJSON]);

  const getReminderPreference = (type: 'email' | 'text', reminderDay: ApptReminderDay) => {
    if (type === 'email') {
      return emailReminderPreference[reminderDay];
    } else if (type === 'text') {
      return textReminderPreference[reminderDay];
    }
    return true;
  };

  const onApptReminderCheckboxChange = async (type: 'email' | 'text', reminderDay: ApptReminderDay) => {
    setCurrentUpdating(type + reminderDay);
    if (parsedJSON) {
      if (type === 'email') {
        if (reminderDay === ApptReminderDay.DayOf) {
          if (!isEmpty(parsedJSON.emailReminderPreference)) {
            parsedJSON.emailReminderPreference.dayOf = !parsedJSON.emailReminderPreference.dayOf;
          } else {
            parsedJSON.emailReminderPreference = {
              dayOf: !(parsedJSON.emailReminders ?? true),
              threeDaysBefore: parsedJSON.emailReminders ?? true,
            };
            parsedJSON.emailReminders = undefined;
          }
        } else if (reminderDay === ApptReminderDay.ThreeDaysBefore) {
          if (!isEmpty(parsedJSON.emailReminderPreference)) {
            parsedJSON.emailReminderPreference.threeDaysBefore = !parsedJSON.emailReminderPreference.threeDaysBefore;
          } else {
            parsedJSON.emailReminderPreference = {
              dayOf: parsedJSON.emailReminders ?? true,
              threeDaysBefore: !(parsedJSON.emailReminders ?? true),
            };
            parsedJSON.emailReminders = undefined;
          }
        }
        await updateFormAnswerGroup();
      } else if (type === 'text') {
        if (reminderDay === ApptReminderDay.DayOf) {
          if (!isEmpty(parsedJSON.textReminderPreference)) {
            parsedJSON.textReminderPreference.dayOf = !parsedJSON.textReminderPreference.dayOf;
          } else {
            parsedJSON.textReminderPreference = {
              dayOf: !(parsedJSON.textReminders ?? true),
              threeDaysBefore: parsedJSON.textReminders ?? true,
            };
            parsedJSON.textReminders = undefined;
          }
        } else if (reminderDay === ApptReminderDay.ThreeDaysBefore) {
          if (!isEmpty(parsedJSON.textReminderPreference)) {
            parsedJSON.textReminderPreference.threeDaysBefore = !parsedJSON.textReminderPreference.threeDaysBefore;
          } else {
            parsedJSON.textReminderPreference = {
              dayOf: parsedJSON.textReminders ?? true,
              threeDaysBefore: !(parsedJSON.textReminders ?? true),
            };
            parsedJSON.textReminders = undefined;
          }
        }
        await updateFormAnswerGroup();
      }
    }
  };

  return (
    <BaseLayout
      headerProps={{ title: 'Notifications', variant: 'back', onBackClick: () => navigate(paths.settings.main) }}
      footer={null}
    >
      {ApptReminderPreferences.map((preference) => (
        <div key={preference.type}>
          <Container>{preference.heading}</Container>
          {preference.options.map((option) => (
            <NotificationContainer key={option.type}>
              <LeftLabelCheckbox
                labelProps={{
                  style: {
                    color: '#4D4D4D',
                    paddingLeft: '1.25rem',
                  },
                }}
                loading={userDataLoading || (updateLoading && currentUpdating == preference.type + option.type)}
                label={option.display}
                checked={getReminderPreference(preference.type, option.type)}
                onChange={() => onApptReminderCheckboxChange(preference.type, option.type)}
              />
            </NotificationContainer>
          ))}
        </div>
      ))}
      <Container> Motiv engagement TEXT reminders sent on: </Container>
      {reminderDays.map((day) => (
        <NotificationContainer key={day.name}>
          <LeftLabelCheckbox
            labelProps={{
              style: {
                paddingLeft: '1.25rem',
              },
            }}
            loading={updateLoading && currentUpdating == day.name}
            label={`${day.name.charAt(0).toUpperCase()}${day.name.slice(1)}`}
            checked={parsedJSON?.reminderDays?.includes(day.name)}
            onChange={() => onChange(day.name)}
          />
        </NotificationContainer>
      ))}
    </BaseLayout>
  );
};

export default Notification;
