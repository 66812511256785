import { useMutation, useQuery } from '@apollo/client';
import { BOOK_APPOINTMENT, CANCEL_APPOINTMENT, RESCHEDULE_APPOINTMENT } from 'api/appointment/appointment.mutation';
import { GET_CURRENT_USER_DATA } from 'api/user/user.query';
import { setFirstAppointmentLater } from 'hooks/useOnboardingFlow';
import { env } from 'index';
import React, { useCallback, useEffect, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import { paths } from 'router/paths';
import { Maybe } from 'utils/types';

import { Button } from 'components/ui/Button/Button';

import { Container } from './ScheduleActions.styles';
import { ScheduleProps } from './types';

export const ScheduleActions: React.FC<ScheduleProps> = ({
  selectedSlot,
  providerId,
  appointmentTypeId,
  appointmentToRescheduleId,
  keepOriginalAppointmentId,
  onScheduleError,
}) => {
  const { t } = useTranslation('translation', { keyPrefix: 'pages.scheduleAppointment' });
  const navigate = useNavigate();
  const { data: currentUserData } = useQuery(GET_CURRENT_USER_DATA);
  const [executeBookAppointment, { data, loading, error }] = useMutation(BOOK_APPOINTMENT);

  const [executeCancelAppointment, { loading: cancelLoading }] = useMutation(CANCEL_APPOINTMENT);

  const [executeReschedule, { data: dataReschedule, loading: loadingReschedule, error: errorReschedule }] =
    useMutation(RESCHEDULE_APPOINTMENT);

  const isIndividualOrientationTypeOrIntake = useMemo(
    () =>
      appointmentTypeId ===
      String(
        env.REACT_APP_INDIVIDUAL_ORIENTATION_APPOINTMENT_TYPE_ID || env.REACT_APP_COACH_APPOINTMENT_FIRST_TIME_TYPE_ID,
      ),
    [appointmentTypeId],
  );

  const handleLater = async () => {
    setFirstAppointmentLater();
    navigate(appointmentToRescheduleId ? paths.appointments : paths.homePage);
  };

  const onSuccess = useCallback(
    async (appointmentId: Maybe<string>) => {
      if (appointmentId) {
        const basicPath = paths.appointmentActionConfirmationMeetYourCoach.replace(':appointmentId', appointmentId);
        if (appointmentToRescheduleId) {
          navigate(basicPath.replace(':actionType', 'reschedule-completed'));
        } else {
          navigate(basicPath.replace(':actionType', 'schedule-completed'));
        }
      }
    },
    [appointmentToRescheduleId, navigate],
  );

  const displayErrorIfExists = (error: string | undefined) => {
    if (error && onScheduleError) {
      // Add nicer UI for this
      onScheduleError(error);
    }
  };

  // Book appointment
  useEffect(() => {
    if (
      data?.completeCheckout?.messages === null &&
      !loading &&
      !error &&
      currentUserData?.currentUser?.id &&
      data?.completeCheckout?.appointment?.provider?.id
    ) {
      onSuccess(data.completeCheckout.appointment?.id);
    }
    const apiError = data?.completeCheckout?.messages?.[0]?.message;
    displayErrorIfExists(apiError);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [data, loading, error, navigate, onSuccess, currentUserData?.currentUser?.id]);

  // Reschedule apointment
  useEffect(() => {
    if (appointmentToRescheduleId) {
      if (dataReschedule?.updateAppointment?.messages === null && !loadingReschedule && !errorReschedule) {
        onSuccess(dataReschedule.updateAppointment.appointment?.id);
      }
      const apiError = dataReschedule?.updateAppointment?.messages?.[0]?.message;
      displayErrorIfExists(apiError);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dataReschedule, loadingReschedule, errorReschedule, appointmentToRescheduleId, onSuccess]);

  const reschedule = async () => {
    // Rescheduling curretly means updating appointment time to different time for same provider
    // We want ability to switch provider if they are re-scheduing so will cancel original appointment and create new one
    if (isIndividualOrientationTypeOrIntake) {
      await executeCancelAppointment({
        variables: {
          id: appointmentToRescheduleId,
        },
      });
      await executeBookAppointment({
        variables: {
          input: {
            date: selectedSlot,
            appointment_type_id: appointmentTypeId,
            provider_id: providerId,
          },
        },
      });
    } else {
      executeReschedule({
        variables: {
          user_id: currentUserData?.currentUser?.id,
          client_updating: true,
          datetime: selectedSlot,
          id: appointmentToRescheduleId,
          client_confirmed: true,
        },
      });
    }
  };

  const cancelAppointment = async () => {
    await executeCancelAppointment({
      variables: {
        id: appointmentToRescheduleId,
      },
    });
  };

  const schedule = async () => {
    await executeBookAppointment({
      variables: {
        input: {
          date: selectedSlot,
          appointment_type_id: appointmentTypeId,
          provider_id: providerId,
        },
      },
    });
  };

  const handleSchedule = async () => {
    if (!selectedSlot || !providerId) return;

    const shouldCancelExisting = isIndividualOrientationTypeOrIntake && !keepOriginalAppointmentId;

    try {
      if (shouldCancelExisting && appointmentToRescheduleId) {
        await cancelAppointment(); // Await the cancellation if it's async
      }

      if (isIndividualOrientationTypeOrIntake || !appointmentToRescheduleId) {
        await schedule(); // Schedule a new appointment
      } else if (appointmentToRescheduleId) {
        await reschedule(); // Reschedule the existing appointment
      }
    } catch (error) {
      // Handle any errors that might occur during scheduling/cancellation
      console.error('Error in handleSchedule:', error);
    }
  };

  return (
    <Container>
      <div>
        <Button variant="link" onClick={handleLater}>
          {appointmentToRescheduleId ? t('cancel') : t('doItLater')}
        </Button>
      </div>
      <div>
        <Button variant="primary" disabled={selectedSlot === null || loading || cancelLoading} onClick={handleSchedule}>
          {loading ? t('scheduling') : t('schedule')}
        </Button>
      </div>
    </Container>
  );
};
